import React from "react"
import { Layout } from "components/pageLayouts"

const NotFoundPage = () => {
    return (
        <Layout>
            <div className={"text-center"}>
                <h1>Not Found</h1>
                <p>This Page Doesn't Exist! 404</p>
            </div>
        </Layout>
    )
}

export default NotFoundPage
